import 'toastr/toastr.scss'
import '../metronic/login.scss'

const $ = require('jquery');
import toastr from 'toastr';
window.toastr = toastr;

const toggleShowPassword = () => {
  const $iconHidden = $('.password-hidden');
  const $iconVisible = $('.password-visible');

  $iconVisible.hide();

  $iconHidden.on('click', function () {
    $(this).hide();
    $(this).siblings(".password-visible").show();
    $(this).closest(".form-group-password").find("input").attr("type", "text");
  });

  $iconVisible.on('click', function () {
    $(this).hide()
    $(this).siblings(".password-hidden").show()
    $(this).closest(".form-group-password").find("input").attr("type", "password");
  });

}

// Universal sitewide popups
const toolTips = () => {

  const toolTips = $('.tool-tips');
  console.log(toolTips);

  toolTips.each(function () {

    const toolTipButton = $(this).find('.tool-tip-button');
    const toolTipPopup = $(this).find('.tool-tip-popup');
    const toolTipIcon = $(this).find('.tool-tip-icon');

    const popperInstance = new Popper(toolTipButton, toolTipPopup, {
      placement: 'bottom',
      eventsEnabled: true,
      removeOnDestroy: true,
      modifiers: {
        flip: {
          enabled: true,
          behavior: ['left', 'bottom', 'top'],
          flipVariations: true,
          padding: 20,
        },
        offset: {
          enabled: true,
          offset: '0,10',
        },
        preventOverflow: {
          enabled: true,
          boundariesElement: 'viewport',
          altAxis: true,
          padding: 15,
        },
        arrow: {
          enabled: true,
          element: '.arrow',
        },
        shift: {
          enabled: true
        },
      },
    });

    function showPopper() {
      setTimeout(() => {
        toolTipPopup.attr('data-show', '');
        toolTipIcon.addClass('active');
        popperInstance.update();
      }, 0)
    }

    function hidePopper() {
      toolTipPopup.removeAttr('data-show');
      toolTipIcon.removeClass('active');
    }

    $(document).on('click', function (e) {
      const target = $(e.target);
      if (target.is(toolTipIcon) && !($(target).hasClass('active'))) {
        showPopper();
      } else {
        hidePopper();
      }
    });

  });
}

$(document).ready(() => {
  toggleShowPassword();
  toolTips();
});

$(document).on('turbolinks:load', () => {
  toggleShowPassword();
  toolTips();
});

function flashMessage(text) {
  toastr.options.progressBar = false;
  toastr.options.closeButton = true;
  toastr.options.positionClass = "toast-bottom-right";
  toastr.options.preventDuplicates = true;
  toastr.options.showDuration = 300;
  toastr.options.hideDuration = 1000;
  toastr.options.timeOut = 4000;
  toastr.options.extendedTimeOut = 1000;
  toastr.options.showEasing = "linear";
  toastr.options.hideEasing = "linear";
  toastr.options.showMethod = "fadeIn";
  toastr.options.hideMethod = "fadeOut";
  toastr.info(text, null, {});
}